import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Accordion,
  Button,
  AccordionItem,
  Collapse,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Input,
  Badge,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Form,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import LocationIcon from "../../assets/images/location.svg";
import { Spinner } from "reactstrap";
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import Select from "react-select";
import MyProfile from "./ProfileView";
import Loader from "../../Components/Common/Loader";
import DOMPurify from "dompurify";
// Import Table Data
// import { BaseExample } from '../GridTablesData';
import axios from "axios";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { get_cookie } from "../../helpers/get_cookie";
import Flatpickr from "react-flatpickr";
import classnames from "classnames";
import * as moment from "moment";
import TableContainer from "../../Components/Common/TableContainer";
import { useSelector, useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import userDummy from "../../assets/images/users/user-dummy-img.jpg";
import businessTeam from "../../assets/images/freepik/business-team.jpg";
import businessTeamOld from "../../assets/images/freepik/User research-amico.png";
import { isEmpty } from "lodash";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Rating from "react-rating";
import GoogleAutocomplete from "react-google-autocomplete";
import purplelogo from "../../assets/images/purplelogo.svg";
import searchworkforceimage from "../../assets/images/searchworkforceimage.png";

const SearchWorkforce = () => {
  const user_id = JSON.parse(get_cookie("authUser")).user_id;
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  const all_permissions = JSON.parse(get_cookie("permissions"));
  const [topSkills, setTopSkills] = useState([]);
  const [open, setOpen] = useState(false);
  const [authUserId, setAuthUserId] = useState();
  const [modal_assignModal, setmodal_assignModal] = useState(false);
  const [talentStatus, setTalentStatus] = useState("");
  const [talents, setTalents] = useState([]);
  const [isTalentButton, setTalentButton] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [searchError, setSearchError] = useState("");
  const [locationQuery, setLocationQuery] = useState("");
  const [radiusQuery, setRadiusQuery] = useState("");
  const [talentProfileId, setTalentProfileId] = useState();
  const [openProfileId, setOpenProfileId] = useState();
  const [isQuery, setIsQuery] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [activeButtonIndex, setActiveButtonIndex] = useState(null);
  const [talentsCount, setTalentsCount] = useState(0);
  const [skillCount, setSkillCount] = useState(0);
  const [geoCount, setGeoCount] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [showAddWorkforceMember, setShowAddWorkforceMember] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [skillSearchId, setSkillSearchId] = useState("");
  //location
  const [radius, setRadius] = useState();
  const [selectedSuburb, setselectedSuburb] = useState();
  const [inputValue, setInputValue] = useState("");
  const [suburbAlone, setselectedSuburbAlone] = useState();
  const [selectedPostcode, setselectedPostcode] = useState();
  const [selectedState, setselectedState] = useState([]);
  const [selectedLatitude, setselectedLatitude] = useState("");
  const [selectedLongitude, setselectedLongitude] = useState("");
  const [isModalLoading, setIsModalLoading] = useState(false);

  const toggleDefaultCanvas = (index) => {
    setOpen(!open);
  };
  //css stylings for random bg color and default profile pic
  const pastelColors = [
    "#fd99a8", // Plum
    "#FFA07A", // LightSalmon
    "#FFD700", // Gold
    "#90df90", // PaleGreen
    "#8bdddd", // PaleTurquoise
    "#af91e3", // Lavender
    "#f791eb", // LightPink
  ];
  // Define dark colors
  const [showMoreList, setShowMoreList] = useState([]);
  function tog_assignModal() {
    setmodal_assignModal(!modal_assignModal);
  }
  const toggleShowMore = (index) => {
    const updatedShowMoreList = [...showMoreList];
    updatedShowMoreList[index] = !updatedShowMoreList[index];
    setShowMoreList(updatedShowMoreList);
  };

  function stripHtmlTags(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }

  // const navigate = useNavigate(); // Create a useNavigate instance
  useEffect(() => {
    const fetchData = async () => {
      try {
        const all_permissions = JSON.parse(get_cookie("permissions"));
        const add_workforce_member = all_permissions?.includes(
          "add_workforce_member"
        );
        setShowAddWorkforceMember(add_workforce_member);

        const userDetailsRes = await axios.get("get-user-details");
        console.log(userDetailsRes);
        setAuthUserId(userDetailsRes.id);

        const browseWorkforceSkillsRes = await axios.get(
          "browse-workforce-skills"
        );
        console.log(browseWorkforceSkillsRes);
        setTopSkills(browseWorkforceSkillsRes.top_skills);

        setisLoading(true); // Set loading state to true before fetching permissions
      } catch (err) {
        console.error(err);
      } finally {
        setisLoading(false); // Set loading state to false after all operations
      }
    };

    fetchData();
  }, []);
  const [lastSearchType, setLastSearchType] = useState("");

  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);

  const handleInputChange = (e) => {
    const value = e.target.value;

    setSearchQuery(value);

    // Call the API to fetch suggestions
    if (value.trim().length >= 3) {
      axios
        .post("/show-skill-keywords", {
          keyword: value, // Send the keyword as part of the POST request body
        })
        .then((response) => {
          console.log("true");
          setSuggestions(response);
          setShowDropdown(true);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);
        });
    } else {
      setSuggestions([]);
      setShowDropdown(false);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    console.log(suggestion, "valuevaluevalue");
    setSearchQuery(suggestion);
    setSuggestions([]); // Clear suggestions on click
    setShowDropdown(false);
  };

  useEffect(() => {
    if (!searchQuery && !selectedSuburb && !radius) {
      // handleSearch(); ///(it causes console issue )//
    }
  }, [searchQuery, selectedSuburb, radius]);

  useEffect(() => {
    if (isReset) {
      handleSearch();
      setIsReset(false); // Reset the flag after handling search
    }
  }, [isReset]);

  // const handleSearch = () => {
  //   // setLastSearchType("text-search");
  //   setSkillSearchId("");
  //   setisLoading(true);
  //   // Validate that both location and radius are provided together
  //   if (radius && !selectedSuburb) {
  //     toast.error("Both location and radius must be filled together.");
  //     return;
  //   }

  //   // Determine the type of search
  //   let searchType = "";
  //   if (selectedSuburb && radius && searchQuery?.trim() !== "") {
  //     searchType = "sendall";
  //   } else if (selectedSuburb) {
  //     searchType = "geo-search";
  //   } else if (searchQuery?.trim() !== "") {
  //     searchType = "text-search";
  //   }

  //   // Set the search type
  //   setLastSearchType(searchType);

  //   // Prepare form data for the search
  //   const formData = {
  //     query: searchQuery,
  //     user_id: authUserId,
  //     count: 0,
  //   };

  //   if (searchType === "text-search") {
  //     formData.query = searchQuery;
  //   } else if (searchType === "geo-search" || searchType === "sendall") {
  //     formData.selectedLatitude = selectedLatitude;
  //     formData.selectedLongitude = selectedLongitude;
  //     formData.radius = radius || 1;
  //     if (searchType === "sendall") {
  //       formData.query = searchQuery;
  //     }
  //   }

  //   // Reset state and set loading
  //   setSkillSearchId("");
  //   setisLoading(true);

  //   // Perform the search
  //   axios
  //     .post("/search-talents", formData)
  //     .then((response) => {
  //       console.log(response, "responsbehbbfhrbhfrbfbrhfbhrbfhbrh");
  //       if (searchType === "geo-search" && response.count == 0) {
  //         toast.success("No workers available for your preferred location.", {
  //           theme: "light",
  //         });
  //         setTalents([]);
  //         setIsQuery(false);
  //       } else {
  //         setTalents(response);
  //         setIsQuery(true);
  //       }
  //       setisLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error("Error occurred:", error);
  //       setisLoading(false);
  //     });
  // };

  useEffect(() => {
    console.log(suggestions); // Check if suggestions are correctly set
  }, [suggestions]);

  const geocodeAddress = async (address) => {
    const apiKey = "AIzaSyDPs4Niq_SNsZTPdNDNoOVfErDW2CQ4S3k"; // API key for Google Geocoding API
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${apiKey}`; // Construct the API request URL

    try {
      // Step 1: Fetch the geocoding data from the API
      const response = await fetch(url);
      const data = await response.json();
      console.log("Geocode API Response:", data);

      // Step 2: If the API response contains valid results, extract the location
      if (data.results && data.results.length > 0) {
        const location = data.results[0].geometry.location; // Extract latitude & longitude
        const addressComponents = data.results[0].address_components;
        const formattedAddress_fulladd = data.results[0].formatted_address;

        // setSelectedLocation({
        //   latitude: location.lat,
        //   longitude: location.lng,
        // });
        // Extract address components
        const { suburb, state, postalCode, country, formattedAddress } =
          extractAddressDetails(addressComponents);

        // Log the retrieved latitude and longitude
        console.log(
          `geocodeAddress = Latitude: ${location.lat}, Longitude: ${location.lng}`
        );

        // Return the latitude, longitude, and formatted address
        return {
          latitude: location.lat,
          longitude: location.lng,
          formattedAddress: formattedAddress,
          state: state,
          suburb: suburb,
          postal_code: postalCode,
          country: country,
        };
      } else {
        // If no results from the API, handle the error
        console.error("No results from geocoding API");
        toast.warning("Error", "Unable to fetch location from the address.");
        return null;
      }
    } catch (error) {
      // Handle any errors during the API request
      console.error("Error during geocoding fetch:", error);
      toast.warning("Error", "Failed to fetch location from Google.");
      return null;
    }
  };

  /* ---- Helper Function for Extracting Address Components ---- */
  const extractAddressDetails = (addressComponents) => {
    console.log(addressComponents, "addressComponents");
    const suburb =
      addressComponents.find((component) =>
        component.types.includes("locality")
      )?.long_name || ""; // Default to empty string if not found

    const state =
      addressComponents.find((component) =>
        component.types.includes("administrative_area_level_1")
      )?.short_name || ""; // Fallback to empty string

    const country =
      addressComponents.find((component) => component.types.includes("country"))
        ?.long_name || ""; // Fallback to empty string

    const postalCode =
      addressComponents.find((component) =>
        component.types.includes("postal_code")
      )?.long_name || ""; // Fallback to empty string

    // Rebuild the full formatted address excluding postal code
    const formattedAddress = `${suburb ? suburb + ", " : ""}${state}`;

    return { formattedAddress, suburb, state, postalCode, country };
  };
  /* ---- End of Helper Function ---- */

  const handleSearch = async () => {
    setSkillSearchId("");
    setisLoading(true);

    // Validate that both location and radius are provided together
    if (radius && !selectedSuburb) {
      toast.error("Both location and radius must be filled together.");
      setisLoading(false);
      return;
    }

    // Determine the type of search
    let searchType = "";
    if (selectedSuburb && radius && searchQuery?.trim() !== "") {
      searchType = "sendall";
    } else if (selectedSuburb) {
      searchType = "geo-search";
    } else if (searchQuery?.trim() !== "") {
      searchType = "text-search";
    }

    // const geocodedData = await geocodeAddress(selectedSuburb);
    let geocodedData = null;
    if (selectedSuburb) {
      try {
        geocodedData = await geocodeAddress(selectedSuburb);
      } catch (error) {
        console.error("Error geocoding address:", error);
      }
    }

    // if (
    //   !geocodedData ||
    //   !geocodedData.latitude ||
    //   !geocodedData.longitude ||
    //   !geocodedData.formattedAddress
    // ) {
    //   throw new Error("Invalid address data received");
    // }

    console.log(geocodedData, "geocodedData");

    // Set the search type
    setLastSearchType(searchType);

    // Prepare form data for the search
    const formData = {
      query: searchQuery, // Use the name part only
      user_id: authUserId,
      business_id: business_id,
      count: 0,
    };

    if (searchType === "text-search") {
      formData.query = searchQuery;
    } else if (searchType === "geo-search" || searchType === "sendall") {
      const {
        latitude,
        longitude,
        formattedAddress,
        state,
        suburb,
        postal_code,
        country,
      } = geocodedData;

      setselectedSuburb(formattedAddress);

      formData.selectedLatitude = latitude;
      formData.selectedLongitude = longitude;
      formData.radius = radius || 1;
      if (searchType === "sendall") {
        formData.query = searchQuery;
      }
    }

    // Perform the search
    axios
      .post("/search-talents", formData)
      .then((response) => {
        if (searchType === "geo-search" && response.count === 0) {
          toast.success("No workers available for your preferred location.", {
            theme: "light",
          });
          setTalents([]);
          setIsQuery(false);
        } else {
          setTalents(response);
          setIsQuery(true);
        }
        setisLoading(false);
      })
      .catch((error) => {
        console.error("Error occurred:", error);
        setisLoading(false);
      });
  };

  //
  const searchProfiles = () => {
    setLastSearchType("geo-search");
    setSkillSearchId("");
    setisLoading(true);
    const formData = {
      user_id: authUserId,
      business_id: business_id,
      selectedLatitude: selectedLatitude,
      selectedLongitude: selectedLongitude,
      radius: radius,
      type: "geo-search",
      count: 0,
      selectedSuburb: selectedSuburb,
    };
    if (selectedSuburb?.trim() !== "") {
      axios
        .post("/search-talents", formData)
        .then((res) => {
          if (res.length == 0) {
            toast.success("No workers available for your preferred location.", {
              theme: "light",
            });
            setTalents([]);
            setIsQuery(false);
            setisLoading(false);
          } else {
            setTalents(res);
            setIsQuery(true);
            setisLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error occurred:", error);
          setisLoading(false);
        });
    } else {
      setTalents([]);
      setIsQuery(false);
      setisLoading(false);
    }
  };

  const loadMore = () => {
    if (skillSearchId == "") {
      setShowLoader(true);
      if (lastSearchType === "text-search") {
        const formData = {
          type: "text-search",
          query: searchQuery,
          user_id: authUserId,
          business_id: business_id,
          count: talentsCount + 1,
        };
        if (searchQuery?.trim() !== "") {
          axios.post("/search-talents", formData).then((res) => {
            setTalents((prevTalents) => [...prevTalents, ...res]);
            setIsQuery(true);
            setTalentsCount(talentsCount + 1);
            setShowLoader(false);
          });
        } else {
          setTalents([]);
          setIsQuery(false);
          setShowLoader(false);
        }
      } else if (
        lastSearchType === "geo-search" ||
        lastSearchType === "sendall"
      ) {
        const formData = {
          user_id: authUserId,
          business_id: business_id,
          selectedLatitude: selectedLatitude,
          selectedLongitude: selectedLongitude,
          radius: radius,
          count: geoCount + 1,
          query: "geo-search", // default value
          selectedSuburb: selectedSuburb,
        };

        if (lastSearchType === "sendall") {
          formData.query = "sendall";
        }

        if (selectedSuburb?.trim() !== "") {
          axios
            .post("/search-talents", formData)
            .then((res) => {
              console.log(res, "geosearch");
              setTalents((prevTalents) => [...prevTalents, ...res]);
              setIsQuery(true);
              setGeoCount(geoCount + 1);
              setShowLoader(false);
            })
            .catch((error) => {
              console.error("Error occurred:", error);
              setisLoading(false);
            });
        } else {
          setTalents([]);
          setIsQuery(false);
          setShowLoader(false);
        }
      }
    } else {
      loadMoreSkillSearch();
    }
  };
  const handleSkillSearch = (skillId, index) => {
    setSkillSearchId(skillId);
    setisLoading(true);
    setActiveButtonIndex(index);
    // setSkillCount(0);
    const formData = {
      user_id: authUserId,
      business_id: business_id,
      skill_id: skillId,
      count: 0,
    };
    if (skillId) {
      axios
        .post("skill-data-filter", formData)
        .then((res) => {
          console.log(res);
          setTalents(res);
          setIsQuery(true);
          setisLoading(false);
        })
        .then((err) => {
          console.log(err);
        });
    } else {
      setIsQuery(false);
    }
  };
  const loadMoreSkillSearch = () => {
    setisLoading(true);
    //setActiveButtonIndex(index);
    const formData = {
      user_id: authUserId,
      business_id: business_id,
      skill_id: skillSearchId,
      count: skillCount + 1,
    };
    console.log(formData, "l_formData");
    if (skillSearchId) {
      axios
        .post("skill-data-filter", formData)
        .then((res) => {
          console.log(res, "loadmore");
          //setTalents(res);
          setTalents((prevTalents) => [...prevTalents, ...res]);
          setIsQuery(true);
          setSkillCount(skillCount + 1);
          setisLoading(false);
        })
        .then((err) => {
          console.log(err);
        });
    } else {
      setIsQuery(false);
    }
  };

  const handleReset = () => {
    setSearchQuery("");
    setselectedSuburb("");
    setRadius("");
    setLastSearchType(null);
    setTalents([]);
    setIsReset(true);
  };

  const addToTalentPool = () => {
    const formData = {
      user_id: authUserId,
      business_id: business_id,
      workforce_profile_id: talentProfileId,
    };
    axios
      .post("/add-talent-pool", formData)
      .then((res) => {
        console.log(res);
        setTalentStatus(res.message);

        if (res.success == true) {
          setmodal_assignModal(false);
          setTalentButton(true);
          if (open) {
            setOpen(false);
          }
          toast.success(res.message);
          const updatedTalents = [...talents];
          const talentIndex = updatedTalents.findIndex(
            (talent) => talent.profile_id === talentProfileId
          );
          updatedTalents[talentIndex].status = res.status;
          updatedTalents[talentIndex].message = res.message;
        }
      })
      .then((err) => {
        console.log(err);
      });
  };
  const handleRadiusChange = (e) => {
    // Ensure the input is a valid positive integer with a maximum of 3 digits
    const inputValue = e.target.value.replace(/\D/g, "").substring(0, 3);
    setRadius(inputValue);
  };
  const selectSuburbOption = (place) => {
    // Check if geometry is present
    if (!place.geometry || !place.geometry.location) {
      console.error("Invalid place data:", place);
      return;
    }

    const addressComponents = place?.address_components;

    const suburbComponent = addressComponents?.find((component) =>
      component.types.includes("locality")
    );
    const stateComponent = addressComponents?.find((component) =>
      component.types.includes("administrative_area_level_1")
    );
    const postcodeComponent = addressComponents?.find((component) =>
      component.types.includes("postal_code")
    );

    let selectedSuburb = "";
    if (suburbComponent) {
      selectedSuburb = suburbComponent.long_name;

      // Concatenate state if available
      if (stateComponent) {
        selectedSuburb += `, ${stateComponent.short_name}`;
      }
    }

    let selectedPostcode = "";
    if (postcodeComponent) {
      selectedPostcode = postcodeComponent.long_name;
    }

    // Extract suburb and state separately
    let suburbAlone = suburbComponent?.long_name || "";
    let selectedState = stateComponent?.short_name || "";
    let postcode = selectedPostcode || "";

    // Check if location has latitude and longitude information
    const locationGeometry = place?.geometry;
    const latitude = locationGeometry?.location.lat() || null;
    const longitude = locationGeometry?.location.lng() || null;

    // Update your state variables
    setselectedSuburb(selectedSuburb);
    setselectedSuburbAlone(suburbAlone);
    setselectedPostcode(postcode);
    setselectedState(selectedState);
    setselectedLatitude(latitude);
    setselectedLongitude(longitude);
  };
  const autocompleteService = useRef(null);
  const itemIdRef = useRef(null);
  const handleKeyUp = async (e, inputType, item = "") => {
    const input = e.target.value || inputValue;
    console.log("Input value:", input);
    // Set the itemIdRef to the current item.id
    itemIdRef.current = item.id;

    if (e.key === "Enter") {
      e.preventDefault();
      try {
        const predictions = await getPlacePredictions(input, inputType);

        if (predictions && predictions.length > 0) {
          const firstPlaceId = predictions[0].place_id;
          const request = {
            placeId: firstPlaceId,
            fields: [
              "address_components",
              "formatted_address",
              "geometry",
              "place_id",
            ],
          };

          const placesService = new window.google.maps.places.PlacesService(
            document.createElement("div")
          );
          placesService.getDetails(request, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              if (inputType === "suburb") {
                selectSuburbOption(place);
              }
            } else {
              console.error("Error fetching place details:", status);
            }
          });
        }
      } catch (error) {
        console.error("Error fetching predictions:", error);
      }
    }
  };
  const getPlacePredictions = (input, inputType) => {
    return new Promise((resolve, reject) => {
      if (!autocompleteService.current) {
        autocompleteService.current =
          new window.google.maps.places.AutocompleteService();
      }

      const types = inputType === "location";

      autocompleteService.current.getPlacePredictions(
        {
          input,
          types,
          componentRestrictions: { country: "au" },
        },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            resolve(predictions);
          } else {
            reject(status);
          }
        }
      );
    });
  };
  document.title = "Search Workforce | Get Rostered";

  // Add to Talent Pool Image
  const s3ImgUrl =
    "https://s3.ap-southeast-2.amazonaws.com/devwork.getrostered.app/workforce-profile-pictures/";

  const [userProfile, setUserProfile] = useState([]);

  const getWorkData = (workforce_id) => {
    const formData = {
      business_user_id: authUserId,
      business_id: business_id,
      profile_id: workforce_id,
    };
    axios
      .post("/get-work-details", formData)
      .then((res) => {
        console.log(res, "work details");
        if (res.message === "Success") {
          setUserProfile(res.data.user_profile);
          setIsModalLoading(false);
        }
      })
      .then((err) => {
        console.log(err);
      });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Search Workforce" />

          <Row className="mt-3">
            <Col lg={12}>
              <Card className="ribbon-box shadow-none">
                <CardBody>
                  <Row className=" mt-0">
                    <Col
                      xl={4}
                      className="d-flex gap-2 align-items-center mobile-view"
                    >
                      <div className="search-text-box flex-grow-1">
                        {isLoading ? (
                          <h5 className="placeholder-glow mt-1">
                            <span className="placeholder col-12"></span>
                          </h5>
                        ) : (
                          <div className="pb-0 position-relative">
                            <i
                              className="bx bxs-search-alt-2  position-absolute  icon-left"
                              style={{
                                color: "#865ce2",
                                top: "50%",
                                left: "15px",
                                transform: "translateY(-50%)",
                                fontSize: "1.5rem",
                              }}
                            ></i>

                            <input
                              type="text"
                              className="search-text-form-control"
                              id="searchJob"
                              value={searchQuery}
                              autoComplete="off"
                              placeholder="Enter Keywords"
                              onChange={handleInputChange}
                              onFocus={() => setShowDropdown(true)}
                              onBlur={() =>
                                setTimeout(() => setShowDropdown(false), 200)
                              } // To allow dropdown interaction
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleSearch();
                                }
                              }}
                              style={{ paddingLeft: "45px" }}
                            />

                            {showDropdown && suggestions && (
                              <ul className="dropdown-menu-search">
                                {/* Skills Section */}
                                {suggestions.skills?.length > 0 && (
                                  <>
                                    <li className="dropdown-header mb-1 mt-1">
                                      Skills
                                    </li>
                                    <li className="dropdown-item mb-1">
                                      <div className="d-flex flex-wrap">
                                        {suggestions.skills
                                          .slice(0, 10)
                                          .map((skill, index) => (
                                            <span
                                              key={`skill-${index}`}
                                              className="badge bg-primary me-1 mb-1"
                                              onClick={() =>
                                                handleSuggestionClick(skill)
                                              }
                                              style={{
                                                cursor: "pointer",
                                                fontSize: 12,
                                              }}
                                            >
                                              {skill}
                                            </span>
                                          ))}
                                      </div>
                                    </li>
                                  </>
                                )}

                                {/* Names Section */}
                                {suggestions.names?.length > 0 && (
                                  <>
                                    <li className="dropdown-header mb-1">
                                      Names
                                    </li>
                                    {suggestions.names
                                      .slice(0, 10)
                                      .map((name, index) => (
                                        <li
                                          key={`name-${index}`}
                                          className="dropdown-item me-2 mb-1"
                                          onClick={() =>
                                            handleSuggestionClick(name)
                                          }
                                        >
                                          <strong style={{ cursor: "pointer" }}>
                                            {name}
                                          </strong>
                                        </li>
                                      ))}
                                  </>
                                )}
                              </ul>
                            )}
                          </div>
                        )}
                      </div>
                    </Col>

                    <Col
                      xl={4}
                      className="d-flex gap-2 align-items-center mobile-view"
                    >
                      <div className="search-text-box flex-grow-1">
                        {isLoading ? (
                          <h5 className="placeholder-glow mt-1">
                            <span className="placeholder col-12"></span>
                          </h5>
                        ) : (
                          <div className="pb-0 position-relative">
                            <i
                              className="bx bxs-map-pin position-absolute icon-left"
                              style={{
                                color: "#865ce2",
                                top: "50%",
                                left: "15px",
                                transform: "translateY(-50%)",
                                fontSize: "1.5rem",
                              }}
                            ></i>
                            <GoogleAutocomplete
                              className="search-text-form-control"
                              apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                              value={selectedSuburb}
                              id="searchLocation"
                              placeholder="Choose Location"
                              onKeyUp={(e) => handleKeyUp(e, "suburb")}
                              onPlaceSelected={selectSuburbOption}
                              onChange={(e) => {
                                setselectedSuburb(e.target.value);
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleSearch();
                                }
                              }}
                              options={{
                                types: ["(regions)"],
                                componentRestrictions: {
                                  country: "au",
                                },
                              }}
                              style={{
                                border: "none",
                                padding: "10px",
                                borderRadius: "20px",
                                paddingLeft: "45px",
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </Col>

                    <Col
                      xl={4}
                      className="d-flex gap-2 align-items-center mobile-view "
                    >
                      <div className="search-text-box flex-grow-1">
                        {isLoading ? (
                          <h5 className="placeholder-glow mt-1">
                            <span className="placeholder col-12"></span>
                          </h5>
                        ) : (
                          <div className="pb-0 position-relative">
                            <i
                              className="bx bx-radar position-absolute icon-left"
                              style={{
                                color: "#865ce2",
                                top: "50%",
                                left: "15px",
                                transform: "translateY(-50%)",
                                fontSize: "1.5rem",
                              }}
                            ></i>
                            <input
                              type="number"
                              className="search-text-form-control"
                              id="searchRadius"
                              value={radius}
                              onChange={handleRadiusChange}
                              autoComplete="off"
                              placeholder="Enter Radius"
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleSearch();
                                }
                              }}
                              style={{ paddingLeft: "45px" }}
                            />
                          </div>
                        )}
                      </div>

                      <button
                        className="search-text-btn "
                        onClick={handleSearch}
                        disabled={isLoading}
                      >
                        <span>
                          <i className=" bx bx-search-alt text-primary search-text-icon"></i>
                        </span>
                      </button>
                      <button
                        className="search-text-btn "
                        onClick={handleReset}
                        disabled={isLoading}
                        style={{ backgroundColor: "rgb(188, 169, 227)" }}
                      >
                        <span>
                          <i className="ri-refresh-line text-primary search-text-icon"></i>
                        </span>
                      </button>
                    </Col>

                  </Row>
                </CardBody>
              </Card>

              <Row>
                {isLoading
                  ? // Render loading placeholders when isLoading is true
                    [...Array(4)].map((_, index) => (
                      <Col key={index} lg={3} className="mb-4 custom-column">
                        <Card
                          className="profile_box_card"
                          style={{ height: "100%" }}
                        >
                          <CardBody className="p-1 text-center">
                            <div className="d-flex justify-content-center mt-4 mb-3">
                              <div className="placeholder-glow">
                                <span
                                  className="placeholder col-12"
                                  style={{
                                    height: "5rem",
                                    width: "5rem",
                                    borderRadius: "50%",
                                  }}
                                ></span>
                              </div>
                            </div>
                            <h5 className="placeholder-glow mt-3">
                              <span className="placeholder col-6"></span>
                            </h5>
                            <p className="placeholder-glow">
                              <span className="placeholder col-4"></span>
                            </p>
                            <p className="placeholder-glow">
                              <span className="placeholder col-8"></span>
                            </p>
                            <div className="skill_set_area mt-2 placeholder-glow">
                              <span className="placeholder col-6 me-2"></span>
                              <span className="placeholder col-6 me-2"></span>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    ))
                  : // Render actual talent cards when isLoading is false
                    talents?.length > 0 &&
                    talents.map((talent, index) => (
                      <Col key={index} lg={3} className="mb-4 custom-column">
                        <Card
                          className="profile_box_card"
                          style={{ height: "100%" }}
                        >
                          <NavLink
                            onClick={() => {
                              setOpenProfileId(talent.profile_id);
                              setTalentProfileId(talent.profile_id);
                              toggleDefaultCanvas();
                            }}
                          >
                            <CardBody className="p-1 text-center">
                              <div className="d-flex justify-content-center mt-4 mb-3">
                                {talent.s3_url ? (
                                  <img
                                    src={talent.s3_url}
                                    alt=""
                                    style={{ height: "5rem", width: "5rem" }}
                                    className="avatar-xxs rounded-circle"
                                  />
                                ) : (
                                  <div
                                    style={{
                                      height: "5rem",
                                      width: "5rem",
                                      backgroundColor:
                                        pastelColors[
                                          index % pastelColors.length
                                        ], // Use a different color for each profile
                                      borderRadius: "50%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "2rem",
                                        color: "#fff",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {talent.name
                                        ? talent.name
                                            .split(" ") // Split the name into words
                                            .slice(0, 2) // Take only the first two words
                                            .map((word) =>
                                              word.charAt(0).toUpperCase()
                                            ) // Get the first character of each word
                                            .join("") // Concatenate the initials together
                                        : ""}
                                    </span>
                                  </div>
                                )}
                              </div>
                              <NavLink
                                onClick={() => {
                                  setOpenProfileId(talent.profile_id);
                                  setTalentProfileId(talent.profile_id);
                                  toggleDefaultCanvas();
                                }}
                              >
                                <h5 className="card-title">{talent.name}</h5>
                              </NavLink>
                              <Rating
                                readonly
                                initialRating={talent.ratings}
                                emptySymbol="mdi mdi-star-outline text-muted"
                                fullSymbol="mdi mdi-star text-warning"
                              />{" "}
                              <p className="workforce_pricing_hour_text mb-0">
                                {talent.hourly_rate
                                  ? `$ ${talent.hourly_rate} / Hr`
                                  : "$- / Hr"}{" "}
                              </p>
                              <p className="location_set_area mb-0">
                                <img
                                  src={LocationIcon}
                                  alt="Location icon"
                                  className="search-location-icon"
                                />
                                <span className="search-location-text">
                                  &nbsp;
                                  {talent.location ? talent.location : "--"}
                                </span>
                              </p>
                              <div className="skill_set_area mt-2">
                                {talent?.skills && talent?.skills.length > 0 ? (
                                  talent?.skills?.map((skill, index) => (
                                    <span
                                      key={index}
                                      className="badge badge-soft-skills fs-12 me-2"
                                    >
                                      {skill}
                                    </span>
                                  ))
                                ) : (
                                  <Badge className="badge-bg-purple mt-1 fs-12 me-2">
                                    None
                                  </Badge>
                                )}
                              </div>
                            </CardBody>
                          </NavLink>
                          <div className="add_to_talent_btn_area ms-4 me-4">
                            {talent.status == null &&
                            all_permissions?.includes(
                              "add_workforce_member"
                            ) ? (
                              <button
                                className="btn btn-talent btn-sm mt-auto"
                                style={{ fontSize: "12px" }}
                                onClick={() => {
                                  tog_assignModal(talent.profile_id);
                                  setTalentProfileId(talent.profile_id);
                                  setUserProfile("");
                                  getWorkData(talent.profile_id);
                                  setIsModalLoading(true);
                                }}
                              >
                                <span className="text">Add to Talent Pool</span>
                              </button>
                            ) : (
                              <button
                                className="btn btn-talent btn-sm mt-auto disabled"
                                disabled
                                style={{
                                  backgroundColor: "#f1eaff",
                                  fontSize: "12px",
                                }}
                              >
                                <span
                                  className="text"
                                  style={{ backgroundColor: "#f1eaff" }}
                                >
                                  {talent.message}{" "}
                                </span>
                              </button>
                            )}
                          </div>
                        </Card>
                      </Col>
                    ))}
              </Row>

              {!isLoading && (
                <>
                  {searchQuery == null && lastSearchType === "" ? (
                    <Card className="full-height-card mt-1">
                      <CardBody className="d-flex flex-column align-items-center justify-content-center p-4 ">
                        <>
                          <Row className="w-100">
                            <Col
                              md={12}
                              className="d-flex justify-content-center align-items-center mb-3 imagesearch"
                            >
                              <img src={searchworkforceimage} alt="Logo" />
                            </Col>
                            <Label className="text-center custom-label ms-4">
                              "Start Building Your Dream Team Today"
                            </Label>
                          </Row>
                          <Row className="w-100">
                            <Col
                              md={12}
                              className="d-flex justify-content-center align-items-center"
                            >
                              {/* <Label className="text-center custom-label ms-4">
                                "Start Building Your Dream Team Today"
                              </Label> */}
                            </Col>
                          </Row>
                        </>
                      </CardBody>
                    </Card>
                  ) : (
                    (talents?.length === 0 || talents?.count == 0) && (
                      <Card className="full-height-card mt-1">
                        <CardBody className="d-flex flex-column align-items-center justify-content-center p-4 ">
                          <>
                            <Row className="w-100">
                              <Col
                                md={12}
                                className="d-flex justify-content-center align-items-center mb-3 imagesearch"
                              >
                                <img src={searchworkforceimage} alt="Logo" />
                              </Col>
                              <Label className="text-center custom-label ms-4">
                                "No records found"
                              </Label>
                            </Row>
                            <Row className="w-100">
                              <Col
                                md={12}
                                className="d-flex justify-content-center align-items-center "
                              >
                                {/* <Label className="text-center custom-label ms-4">
                                  "No records found"
                                </Label> */}
                              </Col>
                            </Row>
                          </>
                        </CardBody>
                      </Card>
                    )
                  )}
                </>
              )}

              {!isLoading && talents?.length > 8 && (
                <Col lg={12}>
                  <div className="text-center mb-3">
                    {showLoader && (
                      <div className="loader">
                        <button
                          className="btn btn-primary rounded-pill"
                          onClick={loadMore}
                        >
                          <i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                          Load More
                        </button>
                      </div>
                    )}
                    {!showLoader && (
                      <button
                        className="btn btn-primary rounded-pill"
                        onClick={loadMore}
                      >
                        <i className="mdi mdi-spin fs-20 align-middle me-2"></i>
                        Load More
                      </button>
                    )}
                  </div>
                </Col>
              )}

              {/* <Modal
                size="md"
                isOpen={modal_assignModal}
                toggle={() => {
                  tog_assignModal();
                }}
              >
                <ModalHeader></ModalHeader>
                <ModalBody className="text-center">
                  <h5 className="text-center">
                    Ready to add this talented individual to your Talent Pool?
                  </h5>

                  <h5 className="mt-3">Confirm to proceed. </h5>

                  <lord-icon
                    src="https://cdn.lordicon.com/xzalkbkz.json"
                    trigger="loop"
                    style={{ width: "100px", height: "100px" }}
                  ></lord-icon>
                  <Row className="mt-5 d-flex justify-content-center">
                    <Col lg={3} className=" hstack gap-3 p-0 ">
                      <Button
                        className="btn btn-dark"
                        onClick={tog_assignModal}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="btn btn-success"
                        onClick={addToTalentPool}
                      >
                        Confirm
                      </Button>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal> */}
              {isModalLoading ? (
                <Modal
                  size="md"
                  isOpen={modal_assignModal}
                  toggle={() => {
                    tog_assignModal();
                  }}
                  centered
                >
                  <ModalHeader toggle={tog_assignModal}></ModalHeader>
                  <ModalBody className="text-center">
                    <Row>
                      <Col sm={4}>
                        {/* <div className="d-flex justify-content-center mt-4 mb-3"> */}
                        <div className="placeholder-glow">
                          <span
                            className="placeholder col-12 img-thumbnail avatar-xxs rounded-circle"
                            style={{
                              height: "9rem",
                              width: "9rem",
                            }}
                          ></span>
                          {/* </div> */}
                        </div>
                      </Col>
                      <Col sm={8}>
                        <div className="confirmationText">
                          <h5 className="text-left">
                            <span className="placeholder col-8"></span>
                            <span className="placeholder col-6"></span>
                          </h5>
                          <p className="subText">
                            <span className="placeholder col-4"></span>
                          </p>
                          <div className="d-flex flex-wrap gap-2">
                            <Button
                              className="w-lg cancelButton"
                              color="primary"
                              // outline
                              // onClick={tog_assignModal}
                              // className="btn btn-talent btn-sm mt-auto"
                            >
                              <span className="placeholder col-6"></span>
                            </Button>
                            <Button
                              className="w-lg confirmButton"
                              color="primary"
                              // style={styles.confirmButton}
                              // onClick={addToTalentPool}
                            >
                              <span className="placeholder col-6"></span>
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </ModalBody>
                </Modal>
              ) : (
                <Modal
                  size="md"
                  isOpen={modal_assignModal}
                  toggle={() => {
                    tog_assignModal();
                  }}
                  centered
                >
                  <ModalHeader toggle={tog_assignModal}></ModalHeader>
                  <ModalBody className="text-center">
                    <Row>
                      <Col lg={4}>
                        {userProfile?.profile_pics ? (
                          <img
                            src={`${userProfile?.profile_pics	}`}
                            alt="user-img"
                            style={{ height: "9rem", width: "9rem" }}
                            className=" img-thumbnail avatar-xxs rounded-circle"
                          />
                        ) : (
                          <img
                            src={userDummy}
                            alt="user-img"
                            style={{ height: "9rem", width: "9rem" }}
                            className=" img-thumbnail avatar-xxs rounded-circle"
                          />
                        )}
                      </Col>
                      <Col lg={8}>
                        {/* fghfh */}
                        <div className="confirmationText">
                          <h5 className="text-left">
                            Ready to add this talented individual to your Talent
                            Pool?
                          </h5>
                          <p className="subText">Confirm to proceed</p>
                          <div className="d-flex flex-wrap gap-2">
                            <Button
                              className="w-lg cancelButton"
                              color="primary"
                              // outline
                              onClick={tog_assignModal}
                              // className="btn btn-talent btn-sm mt-auto"
                            >
                              Cancel
                            </Button>
                            <Button
                              className="w-lg confirmButton"
                              color="primary"
                              // style={styles.confirmButton}
                              onClick={addToTalentPool}
                            >
                              Confirm
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </ModalBody>
                </Modal>
              )}
              {/*<Offcanvas
                isOpen={open}
                toggle={toggleDefaultCanvas}
                style={{ minWidth: "75%", borderLeft: 0 }}
                direction="end"
                id="offcanvasExample"
              >
                <OffcanvasBody
                  className="grey-overall-bg p-0"
                  style={{ overflowX: "hidden" }}
                >
                  <MyProfile
                    profileId={talentProfileId}
                    addToTalentPool={addToTalentPool}
                    isSearchWorkforce={true}
                  />
                </OffcanvasBody>
              </Offcanvas>*/}
              <Offcanvas
                isOpen={open}
                toggle={toggleDefaultCanvas}
                style={{ minWidth: "70%", borderLeft: 0 }}
                direction="end"
                id="offcanvasExample"
              >
                <OffcanvasBody className="white-overall-bg p-4">
                  <MyProfile
                    profileId={talentProfileId}
                    addToTalentPool={addToTalentPool}
                    isSearchWorkforce={true}
                  />
                </OffcanvasBody>
              </Offcanvas>
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SearchWorkforce;
