import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Nav,
  Table,
  Spinner,
  UncontrolledDropdown,
  UncontrolledTooltip,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Input,
  Label,
  Button,
} from "reactstrap";
import Loader from "../../Components/Common/Loader";
import DeleteModal from "../../Components/Common/DeleteModal";
// import Pusher from "pusher-js";
import { Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import classnames from "classnames";
import { get_cookie } from "../../helpers/get_cookie";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment-timezone";

//SimpleBar
import SimpleBar from "simplebar-react";

const SiteDetailPopup = ({showPopup,
  profileId,
  handleclick,
  addToTeam,
  selectedIncomeType,
  setSelectedIncomeType,
  selectedCalendar,
  setSelectedCalendar,
  selectedEarningRate,
  setSelectedEarningRate,
  talentPool,}) => {
  const [payRateWeekDay, setPayRateWeekDay] = useState();
  const [payRateSaturday, setPayRateSaturday] = useState();
  const [payRateSunday, setPayRateSunday] = useState();
  const [payRatePublicHoliday, setPayRatePublicHoliday] = useState();
  const [modal_assignModal, setmodal_assignModal] = useState(false);
  //const [modal_assignModalArchive, setmodal_assignModalArchive] = useState(false);
  //const [userProfile, setUserProfile] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [authUserId, setAuthUserId] = useState();
  //const [openProfileId, setOpenProfileId] = useState();
  const [openTeamDetail, setOpenTeamDetail] = useState(false);
  //const [openWorkforceId, setOpenWorkforceId] = useState(null);
  //const [isOpenLoading, setIsOpenLoading] = useState(false);
  const [teamdetails, setteamdetails] = useState();
  const [payrollCalendars, setPayrollCalendars] = useState([]);
  const [incometype, setincometype] = useState([]);
  const [payrate, setpayrate] = useState([]);
  const [sitedetails, setsitedetails] = useState();
  const [cancelweekday, setcancelweekday] = useState();
  const [cancelsaturday, setcancelsaturday] = useState();
  const [cancelsunday, setcancelsunday] = useState();
  const [cancelholiday, setcancelholiday] = useState();
  const [sitelist, setsitelist] = useState();
  const [worktitle, setworktitle] = useState();
  const [editing, setEditing] = useState(false);
  const [editGender, setEditGender] = useState(false);
  const [editDob, setEditDob] = useState(false);
  const [editedTitle, setEditedTitle] = useState(worktitle); // Assuming item is accessible
  const [customHoverTab, setcustomHoverTab] = useState("1");
  const [addingRow, setAddingRow] = useState(false);
  const User_id = JSON.parse(get_cookie("authUser")).user_id;
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  const [rowsData, setRowsData] = useState([]);
  const [modal_small, setmodal_small] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [selectedSiteId, setSelectedSiteId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [row, setrow] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState();
  const businessId = JSON.parse(get_cookie("authUser")).business_id;
  const [index, setIndex] = useState("");
  const [workId, setworkId] = useState("");
  const [site, setsite] = useState("");
  const [businessApiDetailsExists, setBusinessApiDetailsExists] = useState(false); // To check if business API details exist

  useEffect(() => {
    if (showPopup) {
      if (businessApiDetailsExists) {
        // Fetch the earning rate when popup is shown and business API details exist
        fetchearningrate(profileId);
      } else {
        // Handle the case when business API details don't exist
        getteamdetails(profileId);
        // You could potentially add another function call here if needed
      }
    }
  }, [showPopup]);
  


  const checkBusinessApiDetails = () => {
    axios
      .post("/check-business-api-details", { business_id: businessId })
      .then((res) => {
        setBusinessApiDetailsExists(res.exists); // Set true if business API details exist
      })
      .catch((err) => {
        console.log(err);
      });
  };






  useEffect(() => {
  checkBusinessApiDetails();
  }, [businessId]);
  

  const fetchPayrollCalendars = () => {
    axios
      .post('/getpayroll-calendars', { business_id: businessId })
      .then((res) => {
        if (res.status === 'success') {
          setPayrollCalendars(res.data); // Set the calendar data
        } else {
          console.error(res.message);
        }
      })
      .catch((err) => {
        console.error('Error fetching payroll calendars:', err);
      });
  };



  const fetchearningrate = () => {
    axios
      .post('/get-ordinaryearning-rate', { business_id: businessId,workforce_id :profileId })
      .then((res) => {
        if (res.status =='success') {
          setpayrate(res.data); // Set the calendar data
          console.log(res.Employee,"res.Employeeres.Employeeres.Employee")
          setincometype(res.Incometypeoptions);
          getteamdetails(profileId,res.data,res.Incometypeoptions);
        } else {
          console.error(res.message);
        }
      })
      .catch((err) => {
        console.error('Error fetching payroll calendars:', err);
      });
  };


  

  useEffect(() => {
    if (businessApiDetailsExists){
  fetchPayrollCalendars();
    }
  }, [businessApiDetailsExists]);

  // useEffect(() => {
  // fetchearningrate();
  // }, [businessApiDetailsExists]);




  useEffect(() => {
      if (profileId) {  // Check if profileId exists
        if (businessApiDetailsExists){
          fetchearningrate(profileId);
        }
        
      }
    }, [businessApiDetailsExists,profileId]);
    

  const filteredMenuItemsGeneral = !site.includes("sites");
  const addTableRows = () => {
    const rowsInput = {
      fullName: "",
      emailAddress: "",
      salary: "",
    };
    setRowsData([...rowsData, rowsInput]);
  };

  const addtablerows = () => {
    setAddingRow(true);
    setrow([...row, { Site: "", Address: "", Timezone: "", isEditing: false }]);
  };

  function tog_small() {
    if (hasChanges) {
      setmodal_small(!modal_small);
    }
  }
  useEffect(() => {
    // Set the initial value of editedTitle when the component mounts
    setEditedTitle(worktitle);
  }, [worktitle]);
  const getteamdetails = async (site_id,pay_rate,income_type) => {
    // setIsModalOpen(true);
    setisLoading(true);
    const formData = {
      user_id: User_id,
      business_id: business_id,
      work_id: site_id,
      workforce_profile_id : profileId
    };
    axios
      .post("/get-sitedetails", formData)
      .then((response) => {
        setteamdetails(response.data.details);
        const employeeDetails = response.data.xero_employee_details;
        console.log(employeeDetails,"dnndned");
        let matchedCalendar = null;
        let matchedEarningRate = null;
        let matchedIncomeType = null;
        
        // Check if there is a message in the employeeDetails
        if (employeeDetails.message) {
            // If there is a message, set all to null
            matchedCalendar = null;
            matchedEarningRate = null;
            matchedIncomeType = null;
        } else {
            // If no message, find the matched items based on the IDs provided
            matchedCalendar = payrollCalendars.find(option => option.value === employeeDetails.payroll_calendar_id) || null;
            setSelectedCalendar(matchedCalendar);
            matchedEarningRate = pay_rate.find(option => option.value === employeeDetails.earning_rate_id) || null;
            setSelectedEarningRate(matchedEarningRate);
            matchedIncomeType = income_type.find(option => option.value === employeeDetails.income_type) || null;
            setSelectedIncomeType(matchedIncomeType);
        }
        // Update state with matched options
        setsitedetails(response.data.sites);
        setsitelist(response.data.siteslist);
        console.log(response.data.siteslist);
        setPayRateSunday(response.data.details[0].pay_rate_sunday);
        setworktitle(response.data.details[0].work_title);
        setPayRateSaturday(response.data.details[0].pay_rate_saturday);
        setPayRatePublicHoliday(
          response.data.details[0].pay_rate_public_holiday
        );
        setPayRateWeekDay(response.data.details[0].pay_rate_week_day);
        setcancelweekday(response.data.details[0].pay_rate_week_day | 0.0);
        setcancelsaturday(response.data.details[0].pay_rate_saturday || 0.0);
        setcancelholiday(
          response.data.details[0].pay_rate_public_holiday || 0.0
        );
        setcancelsunday(response.data.details[0].pay_rate_sunday || 0.0);

        setDob(
          moment(response.data.details[0].date_of_birth).format("YYYY-MM-DD")
        );


      // fetchearningrate(profileId);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      });
  };


  console.log(sitelist,"sitelist");

  const closeModal = () => {
    
    setPayRatePublicHoliday("");
    setPayRateWeekDay("");
    setPayRateSaturday("");
    setPayRateSunday("");
    handleclick();
    setEditDob(false);
    setEditGender(false);
  };

  const handlepaysave = async (index, item) => {
    var datavalue = {
      user_id: User_id,
      business_id: business_id,
      id: item.id,
      weekday: payRateWeekDay,
      saturday: payRateSaturday,
      sunday: payRateSunday,
      holiday: payRatePublicHoliday
    };

    axios
      .post("/edit-payrate", datavalue)
      .then((res) => {
        getteamdetails(item.id);
        toast.success("Pay Rate Updated Successfully", { theme: "light" });
      })
      .catch((error) => {
        console.error("Error fetching Rates:", error);
      });
  };
  const enableEdit = () => {
    setEditing(true);
  };

  const emptyValues = () => {
    // setDob(null);
    setGender("");
    setworktitle("");
  };
  const handlesave = (item, type) => {
    console.log(item);
    console.log(
      dob,
      gender,
      worktitle,
      editedTitle,
      type,
      " payload data here"
    );
    var datavalue = {
      user_id: User_id,
      business_id: business_id,
      id: item.id,
      worktitle: editedTitle,
      dob: dob,
      gender: gender,
      type: type,
    };

    axios
      .post("/work-title", datavalue)
      .then((res) => {
        getteamdetails(item.id);
        toast.success(res.message, { theme: "light" });
        setEditGender(false);
        setEditDob(false);
        setEditing(false);
      })
      .catch((error) => {
        console.error("Error fetching Rates:", error);
        toast.error("Talent work details not found", { theme: "light" });
      });
  };

  const saveTableRow = (index, item) => {
    var datavalue = {
      user_id: User_id,
      business_id: business_id,
      id: item.id,
      site_id: selectedSiteId,
    };

    axios
      .post("/save-sitedetails", datavalue)
      .then((res) => {
        console.log(res.success);
        toast.success(res.success, { theme: "light" });
        const updatedRows = [...row];
        updatedRows.splice(index, 1);
        setrow([...updatedRows]);
        getteamdetails(item.id);
      })
      .catch((error) => {
        toast.warning("Site already assigned for this user", {
          theme: "light",
        });
      });
  };
  const handledeletsites = async () => {
    setDeleteModal(true);
    var deleteval = {
      id: deleteId,
      business_id: business_id,
    };
    axios
      .post("/destroy-site", deleteval)
      .then((res) => {
        console.log(res.success);
        toast.success(res.success, { theme: "light" });
        const updatedRows = [...row];
        updatedRows.splice(index, 1);
        setrow([...updatedRows]);
        if (updatedRows.length === 0) {
          setAddingRow(false);
        }
        getteamdetails(workId);
        setDeleteModal(false);
      })
      .catch((error) => {
        console.error("Error fetching lead types:", error);
      });
  };



  const handleSave = async (index) => {};
  const handleChange = (index, event) => {
    const { name, value } = event.target;
    if (name === "name") {
      const updatedProductCategory = [...sitedetails];
      updatedProductCategory[index].site_name = value;
      setsitedetails(updatedProductCategory);
    } else if (name == "site_name") {
      const updatedRowsData = [...row];
      updatedRowsData[index].Site = value;
      setrow(updatedRowsData);
    }
  };


      // Handle calendar selection
      const handleCalendarChange = (selectedOption) => {

        setSelectedCalendar(selectedOption);
      };
        // Handle calendar selection
      const handleearningrate = (selectedOption) => {
          setSelectedEarningRate(selectedOption);
        };

            // Handle calendar selection
      const handleincometype = (selectedOption) => {
              setSelectedIncomeType(selectedOption);
        };


  const handlechangetime = (index, event) => {
    const { name, value } = event.target;
    if (name === "timezone") {
      const updatedProductCategory = [...sitedetails];
      updatedProductCategory[index].timezone = value;
      setsitedetails(updatedProductCategory);
    } else if (name === "timezone_name") {
      const updatedRowsData = [...row];
      updatedRowsData[index].Timezone = value;
      setrow(updatedRowsData);
    }
  };

  const handleCancel = (index) => {
    console.log(index, "index");
    const updatedProductCategory = [...row];
    updatedProductCategory[index].isEditing = false;
    setrow(updatedProductCategory);
  };
  const handlecancel = () => {
    // Reset values to default when cancel is clicked
    setEditedTitle(worktitle);
    setEditing(false);
    emptyValues();
  };
  const handleSiteChange = (index, event) => {
    const selectedSite = event.target.value;

    // Update the state or row data with the selected site
    const updatedRow = [...row];
    updatedRow[index] = { ...updatedRow[index], Site: selectedSite };
    setrow(updatedRow);

    // Find the selected site in the sitelist and get its ID
    const site = sitelist.find((site) => site.site_name === selectedSite);
    if (site) {
      setSelectedSiteId(site.id);
    } else {
      setSelectedSiteId(null);
    }
  };
  const handleDelete = async (data, item, index) => {
    console.log(index);
    var id = data.assign_id;
    var workid = item;
    setIndex(index);
    setDeleteId(id);
    setworkId(workid);
    setDeleteModal(true);
  };
  const handleInputChange = (e) => {
    setEditedTitle(e.target.value);
  };

  const handleNewChange = (index, event) => {
    const { name, value } = event.target;
    if (name === "address") {
      const updatedProductCategory = [...sitedetails];
      updatedProductCategory[index].address = value;
      setsitedetails(updatedProductCategory);
    } else if (name === "address_name") {
      const updatedRowsData = [...row];
      updatedRowsData[index].Address = value;
      setrow(updatedRowsData);
    }
  };
  const getAddressBySite = (selectedSite) => {
    const site = sitelist?.find((site) => site.site_name === selectedSite);
    if (!site) return "";

    const addressParts = [site.address, site.suburb, site.postcode].filter(
      (part) => part
    );
    return addressParts.join(", ");
  };

  const getTimezoneBySite = (selectedSite) => {
    const site = sitelist?.find((site) => site.site_name === selectedSite);
    return site ? site.timezone : "";
  };
  const deleteTableRows = (index) => {
    const updatedRowsData = [...row];
    updatedRowsData.splice(index, 1);
    setrow(updatedRowsData);
    if (updatedRowsData.length === 0) {
      setAddingRow(false);
    }
  };

  return (
    <React.Fragment>
      <div>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handledeletsites}
          onCloseClick={() => setDeleteModal(false)}
        />
        <Modal isOpen={showPopup} centered size="lg">
          <>
            <ModalBody className="p-2 overflow-hidden">
              {teamdetails?.map((item, index) => (
                <div key={index}>
                  <div className="hstack justify-content-between p-3 pb-0">
                    <h5
                      className="text-primary mb-2"
                      style={{ fontSize: "18px" }}
                    >
                      {item.full_name}
                    </h5>
                    <div className="text-end mb-2">
                      <button
                        onClick={() => {
                          if (hasChanges) {
                            tog_small();
                            setEditing(false);
                          } else {
                            closeModal();
                            setEditing(false);
                          }
                        }}
                        type="button"
                        className="custom-close-btn"
                      >
                        <i className="ri-close-fill"></i>
                      </button>
                    </div>
                  </div>

                  {editing ? (
                    <div className="hstack gap-2 align-items-center">
                      <h6 className="ms-3 mt-2 pb-0" id="workTitleLabel">
                        Work Title:
                      </h6>
                      <input
                        type="text"
                        id="workTitle"
                        value={editedTitle}
                        onChange={handleInputChange}
                        className="form-control text-mute ms-2 w-25"
                      />
                      <button
                        onClick={() => handlesave(item, "title")}
                        className="btn btn-primary ms-2"
                      >
                        Save
                      </button>
                      <button
                        onClick={() => handlecancel(item)}
                        className="btn btn-light"
                      >
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <>
                      <div className="d-flex align-items-center">
                        <h6 className="ms-3 mt-2 pb-0" id="workTitleLabel">
                          Work Title:
                        </h6>
                        {item.work_title ? (
                          <span className="ms-2 mt-0">{item.work_title}</span>
                        ) : (
                          <i className="text-muted fs-12 mb-2 mt-2 ms-2">
                            Work title not specified
                          </i>
                        )}
                        &nbsp;&nbsp;
                        <i
                          className="text-primary bx bxs-pencil fs-18 me-1 mt-0 mb-1"
                          onClick={enableEdit}
                        ></i>
                      </div>
                    </>
                  )}

                  <div className="d-flex justify-content-between p-3 pt-0 pb-2">
                    <div className="mt-2">
                      <label
                        htmlFor="firstName"
                        className="form-label hstack fs-14"
                      >
                        <b>Home Address:</b>&nbsp;
                        {item.address ? (
                          item.address
                        ) : (
                          <i className="text-muted fs-12 ms-1">
                            Home address not specified
                          </i>
                        )}
                      </label>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between p-3 pt-0 pb-0">
                    <div className="mt-0">
                      <label
                        htmlFor="firstName"
                        className="form-label hstack fs-14"
                      >
                        <b>Mobile:</b>&nbsp;
                        {item.mobile ? (
                          item.mobile
                        ) : (
                          <i className="text-muted fs-12">
                            Mobile not specified
                          </i>
                        )}
                      </label>
                    </div>
                  </div>

                  {editGender ? (
                    <div className="hstack pb-3 gap-2 align-items-center">
                      <h6 className="ms-3 mt-0 pb-0" id="gender">
                        Gender:
                      </h6>
                      <select
                        value={gender}
                        onChange={(event) => setGender(event.target.value)}
                        className="form-control w-25"
                        name="gender_name"
                      >
                        <option value="" disabled>
                          Select a gender
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                      <div className="hstack gap-1">
                        <button
                          onClick={() => handlesave(item, "gender")}
                          className="btn btn-primary ms-2"
                        >
                          Save
                        </button>
                        <button
                          onClick={() => {
                            setEditGender(false);
                            emptyValues();
                          }}
                          className="btn btn-light "
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="d-flex align-items-center">
                        <h6 className="ms-3 mt-2 pb-0" id="workTitleLabel">
                          Gender:
                        </h6>
                        {item.gender ? (
                          <span className="ms-2 mt-0">{item.gender}</span>
                        ) : (
                          <i className="text-muted fs-12 mb-2 mt-2 ms-2">
                            Gender not specified
                          </i>
                        )}
                        &nbsp;&nbsp;
                        <i
                          className="text-primary bx bxs-pencil fs-18 me-1 mt-0 mb-1"
                          onClick={() => {
                            setEditGender(true);
                          }}
                        ></i>
                      </div>
                    </>
                  )}

                  {editDob ? (
                    <div className="hstack gap-2 align-items-center">
                      <h6 className="ms-3 mt-2 pb-0" id="dob">
                        Date of Birth:
                      </h6>
                      <Flatpickr
                        className="form-control w-25"
                        placeholder="Select "
                        options={{
                          dateFormat: "Y-m-d",
                        }}
                        onChange={(selectedDates) => {
                          setDob(moment(selectedDates[0]).format("YYYY-MM-DD"));
                        }}
                        value={dob}
                      />
                      <div className="hstack gap-1">
                        <button
                          onClick={() => handlesave(item, "dob")}
                          className="btn btn-primary ms-2"
                        >
                          Save
                        </button>
                        <button
                          onClick={() => {
                            setEditDob(false);
                            emptyValues();
                          }}
                          className="btn btn-light "
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="d-flex align-items-center">
                        <h6 className="ms-3 mt-2 pb-0" id="workTitleLabel">
                          Date of Birth:
                        </h6>
                        {item.date_of_birth ? (
                          <span className="ms-2 mt-0">
                            {moment(item.date_of_birth).format("DD MMM, YYYY")}
                          </span>
                        ) : (
                          <i className="text-muted fs-12 mb-2 mt-2 ms-2">
                            D.O.B not specified
                          </i>
                        )}
                        &nbsp;&nbsp;
                        <i
                          className="text-primary bx bxs-pencil fs-18 me-1 mt-0 mb-1"
                          onClick={() => {
                            setEditDob(true);
                          }}
                        ></i>
                      </div>
                    </>
                  )}

                  <hr className="mt-3 ms-3 me-3" />

                  <div className="row p-3 g-3">
                    <div className="mt-0">
                      {/* <Row>

                  <div className="row ms-2 mb-2 ">
                  <div className="col-md-4 ">
                   <h5>Select Payroll Calendar</h5>
                      </div>
                    <div className="col-md-4 ">
                    <h5>Select earning Type </h5>
                    </div>
                    <div className="col-md-4">
                     <h5>Select Income Type </h5>
                    </div>
                  </div>
                  
                

                    <hr className="mt-1 ms-3 me-3" />

                    <div className="row p-3 g-3">
                      <div className="mt-0">
                        {/* <Row>
                          <Col md={6}> */}
                      {/* <h5> */}
                      {/* <Label> */}

{businessApiDetailsExists && (
  <div className="row p-3 g-3">
    <div className="col-md-4 mt-2 ">
      <h5 className="mt-1" style={{ fontSize: "18px" }}>Select Calendar</h5>
    </div>
    <div className="col-md-4 mt-2 ">
      <h5 className="mt-1" style={{ fontSize: "18px" }}>Select PayRate</h5>
    </div>
    <div className="col-md-4 mt-2 ">
      <h5 className="mt-1" style={{ fontSize: "18px" }}>Select IncomeType</h5>
    </div>
  </div>
)}

{businessApiDetailsExists && (
  <div className="row ms-1 mb-4">
    <div className="col-md-4 mt-2 ">
      <Select
        options={payrollCalendars}
        value={selectedCalendar}
        onChange={handleCalendarChange}
        placeholder="Select a payroll calendar"
        className="custom-select" // This will be used to apply custom styles
      />
    </div>
    <div className="col-md-4 mt-2 ">
      <Select
        options={payrate}
        value={selectedEarningRate}
        onChange={handleearningrate}
        placeholder="Select an earning Type"
        className="custom-select" // This will be used to apply custom styles
      />
    </div>
    <div className="col-md-4 mt-2 ">
      <Select
        options={incometype}
        value={selectedIncomeType}
        onChange={handleincometype}
        placeholder="Select an income Type"
        className="custom-select"
      />
    </div>
  </div>
)}

                      <h5 className="mt-1" style={{ fontSize: "18px" }}>
                        Pay Rates
                      </h5>
                      {/* </Label> */}
                      {/* </h5> */}
                      {/* </Col>
                        // </Row> */}
                      <Row>
                        <Col md={2}>
                          <Label className="mt-3">
                            {/* <p className="mt-3" style={{ fontSize: "14px" }}> */}
                            <b>Week days</b>
                            {/* </p> */}
                          </Label>
                        </Col>
                        <Col md={3}>
                          <div className="input-group mt-2">
                            <b className="input-group-text">$</b>
                            <Input
                              type="number"
                              className={`form-control`}
                              value={payRateWeekDay}
                              id="weekday"
                              placeholder="0.00"
                              style={{ paddingRight: "20px" }}
                              onChange={(e) => {
                                setHasChanges(true);
                                setPayRateWeekDay(e.target.value);
                              }}
                            />
                          </div>
                        </Col>

                        <Col md={2}>
                          <Label className="mt-3">
                            {/* <p className="mt-3" style={{ fontSize: "14px" }}> */}
                            <b> Saturday</b>
                            {/* </p> */}
                          </Label>
                        </Col>
                        <Col md={3}>
                          <div className="input-group mt-2">
                            <span className="input-group-text">$</span>
                            <Input
                              type="number"
                              className={`form-control'}`}
                              value={payRateSaturday}
                              id="saturday"
                              placeholder="0.00"
                              style={{ paddingLeft: "20px" }}
                              onChange={(e) => {
                                setHasChanges(true);
                                setPayRateSaturday(e.target.value);
                              }}
                            />
                          </div>
                        </Col>
                        {/* <Col md={1}>
                            <div className="input-group mt-2 mb-2 ms-2">
                              <button
                                className="btn btn-success"
                                onClick={() => handlepaysave(index, item)}
                              >
                                Update
                              </button>
                            </div> */}
                        {/* </Col> */}
                      </Row>

                      <Row className="mb-2">
                        <Col md={2}>
                          <Label className="mt-3">
                            {/* <p  style={{ fontSize: "14px" }}> */}
                            <b> Sunday </b>
                            {/* </p> */}
                          </Label>
                        </Col>
                        <Col md={3}>
                          <div className="input-group mt-2 mb-2">
                            <span className="input-group-text">$</span>
                            <Input
                              type="number"
                              className={`form-control'}`}
                              value={payRateSunday}
                              id="sunday"
                              placeholder="0.00"
                              style={{ paddingLeft: "20px" }}
                              onChange={(e) => {
                                setHasChanges(true);
                                setPayRateSunday(e.target.value);
                              }}
                            />
                          </div>
                        </Col>

                        <Col md={2}>
                          <Label className="mt-3">
                            {/* <p className="mt-3" style={{ fontSize: "14px" }}> */}
                            <b>Public holiday</b>
                            {/* </p> */}
                          </Label>
                        </Col>
                        <Col md={3}>
                          <div className="input-group mt-2 mb-2">
                            <span className="input-group-text">$</span>
                            <Input
                              type="number"
                              className={`form-control'}`}
                              value={payRatePublicHoliday}
                              id="holiday"
                              placeholder="0.00"
                              style={{ paddingLeft: "20px" }}
                              onChange={(e) => {
                                setHasChanges(true);
                                setPayRatePublicHoliday(e.target.value);
                              }}
                            />
                          </div>
                        </Col>

                        <Col>
                          <div className="input-group mt-2 mb-3 ms-2">
                            <button
                              className="btn btn-primary"
                              // style={{
                              //   paddingTop: "6%",
                              //   paddingBottom: "6%",
                              //   marginTop: "3%",
                              // }}
                              onClick={() => {
                                handlepaysave(index, item);
                                setHasChanges(false);
                                setmodal_small(false);
                              }}
                            >
                              Update
                            </button>
                          </div>
                        </Col>
                      </Row>
                      <hr className="mt-1" />
                      <Row>
                        <Col md={6}>
                          <h5 className="mt-1" style={{ fontSize: "18px" }}>
                            {/* <Label> */}
                            {/* <p
                                  className="mt-1"
                                  style={{ fontSize: "18px" }}
                                > */}
                            Sites Assigned
                            {/* </p> */}
                            {/* </Label> */}
                          </h5>
                        </Col>
                      </Row>

                      <CardBody>
                        {filteredMenuItemsGeneral && (
                          <Table className="table table-bordered   align-middle mb-3">
                            <thead className="bg-light">
                              <tr>
                                <th
                                  className="listTypeHead"
                                  style={{ width: "230px" }}
                                >
                                  Sites
                                </th>
                                <th style={{ width: "600px" }}>Location</th>
                                <th style={{ width: "500px" }}>Time Zone</th>

                                <th style={{ width: "145px" }}>Action</th>
                              </tr>
                            </thead>

                            <tbody className="mb-3">
                              {!sitedetails || sitedetails.length === 0
                                ? !addingRow && (
                                    <tr>
                                      <td
                                        colSpan="4"
                                        className="text-center"
                                        style={{ color: "#888888" }}
                                      >
                                        {/* <i>No sites available. Please add a site to continue.</i> */}
                                        <i>No sites added.</i>
                                      </td>
                                    </tr>
                                  )
                                : sitedetails.map((data, index) => (
                                    <tr key={index}>
                                      <td
                                        className="fw-medium"
                                        style={{
                                          maxWidth: "230px",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {data.isEditing ? (
                                          <input
                                            type="text"
                                            value={data.site_name}
                                            onChange={(event) =>
                                              handleChange(index, event)
                                            }
                                            className="form-control"
                                            name="name"
                                          />
                                        ) : (
                                          data.site_name
                                        )}
                                      </td>
                                      <td
                                        className="fw-medium"
                                        style={{
                                          maxWidth: "600px",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {data.isEditing ? (
                                          <input
                                            type="text"
                                            value={data.address}
                                            onChange={(event) =>
                                              handleNewChange(index, event)
                                            }
                                            className="form-control"
                                            name="address"
                                          />
                                        ) : (
                                          getAddressBySite(data.site_name)
                                        )}
                                      </td>
                                      <td
                                        className="fw-medium"
                                        style={{
                                          maxWidth: "600px",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {data.isEditing ? (
                                          <input
                                            type="text"
                                            value={data.timezone}
                                            onChange={(event) =>
                                              handlechangetime(index, event)
                                            }
                                            className="form-control"
                                            name="timezone"
                                          />
                                        ) : (
                                          data.timezone
                                        )}
                                      </td>

                                      <td
                                        className="fw-medium"
                                        style={{
                                          maxWidth: "145px",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        <div className="hstack gap-3 flex-wrap">
                                          {data.isEditing ? (
                                            <>
                                              <button
                                                className="btn btn-success"
                                                onClick={() =>
                                                  handleSave(index)
                                                }
                                              >
                                                Update
                                              </button>
                                              <button
                                                className="btn btn-primary"
                                                onClick={() =>
                                                  handleCancel(index)
                                                }
                                              >
                                                Cancel
                                              </button>
                                            </>
                                          ) : (
                                            <>
                                              <button
                                                className="btn btn-sm btn-soft-danger  ms-3"
                                                onClick={() =>
                                                  handleDelete(
                                                    data,
                                                    item.id,
                                                    index
                                                  )
                                                }
                                              >
                                                <i className="ri-delete-bin-5-fill fs-16"></i>
                                              </button>
                                            </>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              {/* ///Adding for new rows  */}
                              {row &&
                                row.map((data, index) => (
                                  <tr key={index}>
                                    <td>
                                      {/* Dropdown for site_name */}
                                      <select
                                        value={data.Site}
                                        onChange={(event) =>
                                          handleSiteChange(index, event)
                                        }
                                        className="form-control"
                                        name="site_name"
                                      >
                                        <option value="">Select Site</option>
                                        {sitelist?.map((site) => (
                                          <option
                                            key={site.id}
                                            value={site.site_name}
                                          >
                                            {site.site_name}
                                          </option>
                                        ))}
                                      </select>
                                    </td>
                                    <td>
                                      {/* Autocomplete for address based on selected site */}
                                      <input
                                        type="text"
                                        value={getAddressBySite(data.Site)}
                                        onChange={(event) =>
                                          handleNewChange(index, event)
                                        }
                                        name="address_name"
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      {/* Autocomplete for timezone based on selected site */}
                                      <input
                                        type="text"
                                        value={getTimezoneBySite(data.Site)}
                                        onChange={(event) =>
                                          handlechangetime(index, event)
                                        }
                                        className="form-control"
                                        name="timezone_name"
                                      />
                                    </td>
                                    <td>
                                      <div className="hstack gap-2">
                                        <button
                                          className="btn btn-success"
                                          onClick={() =>
                                            saveTableRow(index, item)
                                          }
                                        >
                                          Save
                                        </button>
                                        <button
                                          className="btn btn-primary"
                                          onClick={() => deleteTableRows(index)}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}

                              {/* <TableInput rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} /> */}
                            </tbody>

                            <button
                              className="btn btn-primary mt-3"
                              onClick={addtablerows}
                            >
                              +
                            </button>
                          </Table>
                        )}
                      </CardBody>

                      <div className="col-lg-12">
                        <div className="hstack gap-2 justify-content-end">
                          <Button
                            color="btn btn-light"
                            onClick={() => {
                              if (hasChanges) {
                                tog_small();
                                setEditing(false);
                              } else {
                                closeModal();
                                setEditing(false);
                              }
                            }}
                          >
                            Close
                          </Button>
                          {talentPool == true && (
                            <button
                              className="btn btn-primary"
                            onClick={() =>
                            addToTeam(selectedIncomeType,selectedCalendar,selectedEarningRate)
                            }
                            >
                            Add to Team
                            </button>
                          )}
                        </div>

                        
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </ModalBody>
          </>
        </Modal>
        <Modal size="sm" className="modal-dialog-centered" isOpen={modal_small}>
          <ModalHeader
            id="mySmallModalLabel"
            toggle={() => {
              tog_small();
            }}
          >
            Manage Team
          </ModalHeader>
          <div className="modal-body">
            <p>
              Changes have not been saved. Click 'Update' to save your changes
              or 'Cancel' to discard them.
            </p>
          </div>
          <div className="modal-footer">
            {teamdetails?.map((item, index) => (
              <div key={index}>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    handlepaysave(index, item);
                    setHasChanges(false);
                    setmodal_small(false);
                  }}
                >
                  Update
                </button>
              </div>
            ))}
            <Button
              color="btn btn-light"
              onClick={() => {
                setmodal_small(false);
                closeModal();
                setHasChanges(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default SiteDetailPopup;
